<template>
  <AdminPage contentComponent="AppAdminNewsCreate" />
</template>

<script>
import AdminPage from '@/templates/admin/AppAdminPage'

export default {
  name: 'TheNewsCreate',
  components: {
    AdminPage,
  },
}
</script>